import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Layout from "../components/_common/layout";
import Container from "../components/_common/Container";
import SEO from "../components/_common/seo";
import BackgroundImage from "gatsby-background-image";
import { css } from "@emotion/core";
import Markdown from "react-markdown";
import ReturnButton from "../components/_common/ReturnButton";

const Project = ({
  pageContext: { name, summary, image, description, metas = [], links },
}) => {
  return (
    <Layout subsection="Réalisations">
      <SEO title={name} description={summary} />
      <Container>
        <Header>
          <Target />
          <div>
            <Title>{name}</Title>
            <Subtitle>{summary}</Subtitle>
          </div>
        </Header>
        <AsidePanel>
          <ProjectImage fluid={image.childImageSharp.fluid} />
          <Metas>
            {metas.length > 0 && (
              <dl>
                {metas.map((meta, index) => (
                  <Meta key={index} title={meta.label} text={meta.value} />
                ))}
              </dl>
            )}
            {links && links.length > 0 && (
              <Links>
                {links.map((link, index) => (
                  <A
                    key={index}
                    href={link.url}
                    rel="noreferrer"
                    target="_blank"
                    title={link.label ? link.label : "Voir le site"}
                  >
                    {link.label ? link.label : "Voir le site"}
                  </A>
                ))}
              </Links>
            )}
          </Metas>
        </AsidePanel>
        <MainPanel>
          <Markdown source={description} />
        </MainPanel>
      </Container>
      <ReturnButtonContainer>
        <ReturnButton anchor="achievements" />
      </ReturnButtonContainer>
    </Layout>
  );
};

const Meta = ({ title, text }) => (
  <MetaBox>
    <MetaTitle>{title}</MetaTitle>
    <MetaText>{text}</MetaText>
  </MetaBox>
);

Project.propTypes = {
  pageContext: PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.string,
    image: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    metas: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    link: PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  }),
};

const panelMargin = 0.06;
const mainPanelProportion = 0.55;

const MainPanel = styled.div`
  margin-top: 1rem;
  margin-bottom: 4rem;
  width: ${100 * (mainPanelProportion - 0.5 * panelMargin)}%;
  margin-right: ${100 * panelMargin}%;
  ${props => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-right: 0;
  }
`;

const AsidePanel = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100%;
  width: ${100 * (1 - mainPanelProportion - 0.5 * panelMargin)}%;
  background-color: ${({ theme }) => theme.palette.background};
  z-index: 1000;
  overflow-y: auto;

  ${props => props.theme.breakpoints.down("md")} {
    position: relative;
    width: 100%;
    height: inherit;
    margin-top: 4rem;
    z-index: 0;
  }
`;

const ProjectImage = styled(BackgroundImage)`
  width: 100%;
  flex: 1 1 100%;
  background-color: ${({ theme }) => theme.palette.accent};
  ${props => props.theme.breakpoints.down("md")} {
    padding-top: 75%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem ${100 * panelMargin}% 4rem 0;
  width: ${100 * (mainPanelProportion - 0.5 * panelMargin)}%;

  ${props => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-right: 0;
  }
`;

const Target = styled.div`
  height: ${props => props.theme.decoration.targetSize}rem;
  min-width: ${props => props.theme.decoration.targetSize}rem;
  background-image: url(/images/target.svg);
  background-size: contain;
  margin-right: 1rem;
`;

const Title = styled.h1`
  margin: 0 0 0.5rem 0;
`;

const Subtitle = styled.div`
  font-size: ${props => props.theme.typography.sizes.bigger}rem;
`;

const border = theme => css`
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.white};
  opacity: ${theme.opacity.xLight};
`;

const Metas = styled.div`
  flex: 0 0;
  width: 100%;
  padding: 2rem 2rem 2rem 0;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.background};
  font-size: ${({ theme }) => theme.typography.sizes.regular}rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 2rem 0;
  }
`;

const MetaBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.6rem 0.5rem;

  &::before {
    ${props => border(props.theme)}
    top: 0;
  }

  &:last-of-type {
    &::after {
      ${props => border(props.theme)}
      bottom: 0;
    }
  }
`;

const MetaTitle = styled.dt`
  font-weight: bold;
  min-width: 4rem;
  opacity: ${props => props.theme.opacity.faded};
`;

const MetaText = styled.dd`
  line-height: ${({ theme }) => theme.typography.lineHeights.bigger};
`;

const Links = styled.div`
  padding: 1rem 0 2rem 0;
`;

const A = styled.a`
  display: inline-block;
  margin-right: 2rem;
`;

const ReturnButtonContainer = styled.div`
  position: fixed;
  right: 0;
  top: 2.5rem;
  z-index: 1001;

  ${({ theme }) => theme.breakpoints.down("md")} {
    top: 1.6rem;
  }
`;

export default Project;
