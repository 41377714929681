import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { FaArrowLeft } from "react-icons/fa";
import PropTypes from "prop-types";

const ReturnButton = ({ anchor }) => {
  const linkRef = useRef();

  useEffect(() => {
    const ref = linkRef.current;
    const goBack = event => {
      event.preventDefault();
      window.history.back();
    };
    ref.addEventListener("click", goBack);
    return () => {
      ref.removeEventListener("click", goBack);
    };
  });

  return (
    <Link
      to={anchor ? `/#${anchor}` : "/"}
      title="Retour à l'accueil"
      ref={linkRef}
      className="no-style"
    >
      <Box>
        <Arrow />
        <span>Retour</span>
      </Box>
    </Link>
  );
};

ReturnButton.propTypes = {
  anchor: PropTypes.string,
};

const Box = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.accent};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.background};
  padding: 0.5rem 2rem;
`;

const Arrow = styled(FaArrowLeft)`
  transform: translateY(0.15rem);
  margin-right: 1rem;
`;

export default ReturnButton;
